import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ReactComponent from "../../ReactComponent";
import {ScaleLoader} from "react-spinners";
import {Query} from "react-apollo";
import {loader as gqlLoader} from "graphql.macro";
import {css} from "@emotion/react";

const ListProductVariantQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ListProductVariant extends ReactComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      currLang: this.props.sitecoreContext.language
    };
  }

  render() {
    return(
      <p className="meta-vol">
        <Query query={ListProductVariantQuery} variables={{indexname: this.indexName, rootpath: this.props.productid, language: this.state.currLang, options: {"language": this.state.currLang}}}>
          {({ loading, error, data}) => {
            if (loading)
              return (
                  <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                    <ScaleLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#6e239b'}
                        loading={!this.state.isLoaded}
                    />
                  </div>
              );
            if (error)
              return (
                  <div>Error: {error.message}</div>
              );
            return (
              <>
                {data.productvariant.results && data.productvariant.results.items.map(function (listItem, index) {
                  return(
                    <>
                      {listItem.item.title && listItem.item.title.value}
                      {index < data.productvariant.results.items.length - 1  &&  <>,&nbsp;</>}
                    </>
                  )
                })}
              </>
            )
          }}
        </Query>
      </p>
    )
  }
}

export default withSitecoreContext()(ListProductVariant);

import React from 'react';
import { Image, Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';
import NavLinkAdv from "../../Utils/NavLinkAdv";
import ReactComponent from "../../ReactComponent";

class StoreList extends ReactComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    render() {

        return (
            <div className="dropdown">
                <a className="btn btn-secondary dropdown-toggle text-uppercase helv_std" role="button" id="dropdownfirst"
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {i18next.t("ShopOnlineLabel")}
                </a>
                <div className="dropdown-menu" aria-labelledby="#dropdownfirst">
                    {this.props.fields && this.props.fields.StoreList && this.props.fields.StoreList.map((storeItem, storeIndex) => {
                        return(
                        <NavLinkAdv to={storeItem.fields.StoreLink.value.href} className="dropdown-item" key={storeIndex}>
                            <Image loading="lazy" field={storeItem.fields && storeItem.fields.Brand && storeItem.fields.Brand[0].fields && storeItem.fields.Brand[0].fields.StoreImage} />
                        </NavLinkAdv>
                    );
                })}
                </div>
            </div>
        )
    }
}

export default withSitecoreContext()(StoreList);

import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { css } from '@emotion/react';
import { ScaleLoader } from 'react-spinners';
import ProductGridItem from '../ProductGridItem/index.js';
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";
import {Query} from "react-apollo";
import './productByCat.css';

const ProductByCatQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ProductsByCategory extends ReactComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            currLang: this.props.sitecoreContext.language
        };
    }

    render() {
        return (
            <Query query={ProductByCatQuery} variables={{indexname: this.indexName, categoryId: this.props.sitecoreContext.route.itemId.split('-').join('').toLowerCase(), language: this.state.currLang, options: {"language": this.state.currLang}}}>
                {({ loading, error, data}) => {
                    if (loading)
                        return(
                            <div className='sweet-loading' style={{marginTop:'50px', marginBottom: '50px'}}>
                                <ScaleLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={50}
                                    color={'#6e239b'}
                                    loading={!this.state.isLoaded}
                                />
                            </div>
                        );
                    if (error)
                        return (
                            <div>Error: {error.message}</div>
                        );
                    return (
                        <React.Fragment key={`prodBcat`}>
                            <div className="row_custom_small category__summary__custom">
                                <div className="col-md-12 text-center">
                                    {/* <h1>{this.props.sitecoreContext.route.fields.title.value}</h1> */}
                                    <p>{this.props.sitecoreContext.route.fields.description.value}</p>
                                </div>
                            </div>
                            <div className="row_custom_small list__product__item">
                                {data && data.product.results.items.map((product) => (
                                    <ProductGridItem product={product} columnsize={this.props.fields.GridItemsPerRow}  />
                                ))
                                }
                            </div>
                        </React.Fragment>
                    )
                }}
            </Query>
        )
    }
}

export default withSitecoreContext()(ProductsByCategory);

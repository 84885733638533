import React from 'react';
import {Placeholder, Text, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import './articleLayout.css';

const ArticleLayoutWrapper = (props) => (
  <div className="background-light">
    <div className="row_custom_small">
      <div className="col-md-12 text-left populaireLight custom__title_article">
          <Text field={props.sitecoreContext.route.fields.articleTitle} tag="h1"/>
      </div>
    </div>
    <div className="row_custom_small row-article-layout">
        <div className="col-md-8">
            <Placeholder name="article-main-content" rendering={props.rendering} />
        </div>
        <div className="col-md-4">
            <Placeholder name="article-sidebar-right" rendering={props.rendering}/>
        </div>
    </div>
 </div>
);
export default withSitecoreContext()(ArticleLayoutWrapper);

import React from 'react';
import { Image, Link, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import i18next from 'i18next';
import {NavLink} from "react-router-dom";
import ReactComponent from "../../ReactComponent";

class SalonList extends ReactComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false
        };
    }

    render() {
        return (
            <div className="dropdown ml-4">
                <a className="btn btn-secondary dropdown-toggle text-uppercase helv_std" role="button" id="dropdowntwo"
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {i18next.t("FindSalonLabel")}
                </a>
                <div className="dropdown-menu" aria-labelledby="#dropdowntwo">
                    {this.props.fields && this.props.fields.SalonList && this.props.fields.SalonList.map((salonItem, salonIndex) => (
                        salonItem.fields && salonItem.fields.SalonLink && salonItem.fields.SalonLink.value.href.startsWith("http") === true ?
                            <Link className="dropdown-item" field={salonItem.fields && salonItem.fields.SalonLink} key={salonIndex} ><img loading="lazy" src={salonItem.fields && salonItem.fields.Brand && salonItem.fields.Brand.fields && salonItem.fields.Brand.fields.SalonImage  && salonItem.fields.Brand.fields.SalonImage.value.src} /></Link>
                            :
                            <NavLink to={salonItem.fields.SalonLink.value.href} className="dropdown-item" key={salonIndex} ><img loading="lazy" src={salonItem.fields && salonItem.fields.Brand && salonItem.fields.Brand.fields && salonItem.fields.Brand.fields.SalonImage && salonItem.fields.Brand.fields.SalonImage.value.src} /></NavLink>
                    ))}
                </div>
            </div>
        )
    }
}

export default withSitecoreContext()(SalonList);

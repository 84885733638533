import React from 'react';

const StarRating = ({value}) => {
  let rating = [];
  var integerPart = parseInt(value);
  var decimalPart = (value - integerPart) * 10;

  // Full Star
  for(var x = 1; x<=integerPart; x++) {
    rating.push(<i key={`full-${x}`} className="fa fa-star" aria-hidden="true"/>);
  }

  // Half Star
  if(decimalPart > 0 && decimalPart < 6) {
    rating.push(<i key={`half-${decimalPart}`} className="fa fa-star-half-o" aria-hidden="true"/>);
    integerPart += 1;
  }
  else if (decimalPart > 5 && decimalPart < 10) {
    rating.push(<i key={`halfe-${decimalPart}`} className="fa fa-star" aria-hidden="true"/>);
    integerPart += 1;
  }

  // Remaining Star
  for(var a = 1; a<=(5-integerPart); a++) {
    rating.push(<i key={`remain-${a}`} className="fa fa-star off" aria-hidden="true"/>);
  }

  return(
    <div className="star-cn mb-0">
      {rating}
    </div>
  );
  
}

export default StarRating;

import React from 'react';
import {Text, Image, Link, RichText, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import StarRating from '../StarRating';
import { loader as gqlLoader } from 'graphql.macro';
import GraphQLData from '../../lib/GraphQLData';
import NavLinkAdv from "../../Utils/NavLinkAdv";
import OwlCarousel from "react-owl-carousel2";
import 'react-owl-carousel2/src/owl.carousel.css';
import logo from "../../assets/img/placeholder.png";
import i18next from 'i18next';
import './relatedProducts.css';

const RelatedProductsQuery = gqlLoader('./RelatedProductsQuery.graphql');

class RelatedProducts extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            itemdata: [],
            productVariantURL:  ""      
        };
    }


    render() {
        const graphQLResult = this.props.relatedProductsQuery;
        const {datasource}  = graphQLResult;
        const options = {
            items: 4,
            loop: true,
            lazyLoad: true,
            nav: true,
            dots: false,
            navText: ["<i class='fas fa-angle-left'></i>","<i class='fas fa-angle-right'></i>"],
            responsive: {
              768: {
                items: 4
              },
              426: {
                items: 2
              },
              0: {
                items: 1
              }
            }
          };
        
        return (
            <div className="row_custom_small related__product__custom">
            <div className="col-md-12 pad ratedCustom text-uppercase pageTitleRelated">
                <h2>
                    <div>
                        <Text field={this.props.fields.RelatedProductsHeading} tag="span"/>
                    </div>
                </h2>
            </div>
             {datasource != undefined &&
                <>
                    {
                        datasource.relatedProductsList.targetItems.length > 0 &&
                            <>
                                    <OwlCarousel ref="related" options={options} className={"col-md-12 relatedProd"}>
                                    {datasource &&
                                        datasource.relatedProductsList.targetItems.map((productItem, index) => (
                                            <div key={`item-${index}`} className="carousel_item_related text-center">
                                            <NavLinkAdv to={productItem && productItem.url}>
                                                <div className="portfolio-caption min_font">
                                                <Text field={productItem.title.jss} tag="h2"/>
                                                </div>
                                            
                                                    {productItem.image1.src === null ?
                                                        <img loading="lazy" src={logo} alt="placeholder" className="img-fluid img_custom_full placeholderCustom"/>
                                                        :
                                                        <img loading="lazy" src={productItem.image1 && productItem.image1.src} className="img-fluid img_custom_full"/>
                                                    }
                                                        <StarRating value={productItem.rating.numberValue}/>
                                                        <div className="plus_corner">+</div>
                                                        <NavLinkAdv to={productItem && productItem.url} className="btn btn_custom_o">{i18next.t("SeeMoreButton")}</NavLinkAdv>
                                                   
                                            </NavLinkAdv>
                                            </div>
                                        
                                        ))
                                    }
                                </OwlCarousel>

                            </>
                    }
                </>
            }
            
        </div>
        )
    }
} 

export default GraphQLData(RelatedProductsQuery, { name: 'relatedProductsQuery' })(RelatedProducts);

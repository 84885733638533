import React from "react";
import {NavLink} from "react-router-dom";
import ReactComponent from "../../ReactComponent";
import {withSitecoreContext} from "@sitecore-jss/sitecore-jss-react";

class NavLinkAdv extends ReactComponent{

    render() {
        if(this.props.languageswitcher) {
            return(
                <a href={this.props.to} className={this.props.langactive} key={this.props.key} rel="noopener noreferer">
                    {this.props.children}
                </a>
            )
        }else {
            if(this.props.to.startsWith("http")){
                return(
                    <a href={this.props.to} className={this.props.className} key={this.props.key} target="_blank" rel="noopener noreferer">
                        {this.props.children}
                    </a>
                )
            }else{
                return(
                    <NavLink to={this.props.to} className={this.props.className} key={this.props.key}>
                        {this.props.children}
                    </NavLink>
                )
            }    
        }        
    }

}

export default withSitecoreContext()(NavLinkAdv);

import React from 'react';
import { Text, Link, Image, withSitecoreContext, RichText } from '@sitecore-jss/sitecore-jss-react';
import { css } from '@emotion/react';
import { ScaleLoader } from 'react-spinners';
import NavLinkAdv from "../../Utils/NavLinkAdv";
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";
import {Query} from "react-apollo";
import './productIngredients.css';

const ProductIngredientQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ProductIngredients extends ReactComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            currLang: this.props.sitecoreContext.language
        };
    }

    render() {
        let firstIngredient;
        if (this.props.sitecoreContext.route.fields.productIngredients)
            firstIngredient = this.props.sitecoreContext.route.fields.productIngredients;
        if (this.props.sitecoreContext.route.fields.ingredients)
            firstIngredient = this.props.sitecoreContext.route.fields.ingredients;
                   
        return (
            <>
                <div className="row_custom_small ProductIngredients margin_custom background-light">

                        <div className="col-md-12 text-center">
                            <h2>
                                <div>
                                    <Text field={this.props.fields.Title} tag="span"/>
                                </div>
                            </h2>
                        </div>
                        <div className="text-center list_ingredients">
                            {firstIngredient ?
                                firstIngredient.map((ing) => (
                                    <div className="ingredientItem">
                                        <img loading="lazy" src={ing && ing.fields.Image && ing.fields.Image.value.src} className="img-fluid img_custom_full" />
                                        <div className="name_ing_custom"><Text field={ing && ing.fields.Title} editable={true}/></div>
                                        <RichText tag="p" field={ing && ing.fields.ShortDescription} editable={true} />

                                    </div>
                                ))
                            :
                                <div className={"col-md-4 btn_custom_blue"}>
                                    <i>There is no ingredient defined</i>
                                </div>
                            }
                                        
                              
                        </div>
                        <div className="col-md-12 text-center btn_div_custom">
                            {this.props.fields.ViewAll && this.props.fields.ViewAll.value.href !== '' &&
                                (this.props.fields.ViewAll.value.href.startsWith("http") === true ?
                                    <Link href={this.props.fields.ViewAll.value.href} className="btn" />
                                    :
                                    <NavLinkAdv to={this.props.fields.ViewAll.value.href} className="btn">
                                        {this.props.fields.ViewAll.value.title}
                                    </NavLinkAdv>
                                )
                            }
                        </div>
                    </div>
            </>
        );
    }
}
export default withSitecoreContext()(ProductIngredients);

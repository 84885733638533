import React from 'react';
import { Text, isExperienceEditorActive, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import CarouselQouteItem from '../CarouselQouteItem';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './carouselQuote.css';

class CarouselQoutes extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeDot: 0
    };
    this.setActiveDot = this.setActiveDot.bind(this);
  }

  setActiveDot(dot) {
    this.setState({
      activeDot: dot
    })
    
  }

  componentDidMount(){
    // Hack to get the text always on one line and taking all space
    setTimeout(function(){
      document.querySelectorAll(".overSlide h2").forEach(elem =>{
        while(elem.offsetHeight > 73){
          let style = window.getComputedStyle(elem, null).getPropertyValue('font-size');
          let fontSize = parseFloat(style); 
          elem.style.fontSize = (fontSize -1) + 'px'
        }    
      })
    }, 500)
    
  }

  render () {

    const settings = {
      arrows: false,
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: parseInt(this.props.fields.ItemPerSlide.value),
      slidesToScroll: 1,
      draggable: true,
    };

    const contentList = this.props.fields.QuotesList;

    const dots = [];
    
    for (let i = 0; i <= contentList.length; i++) {
      dots.push(
        <div className="owl-dot" style={{ display: "inline-block" }} key={i}>
          <a href="#" 
            className={this.state.activeDot === i ? "selected" : ""} onClick={() => this.setActiveDot(i)}>
            <span>{i}</span>
          </a>
        </div>
      );
    }

    return (
      
      <div className="section pt-7 pb-7 carousel_custom">
        <div className="row">
          <div data-wow-delay="0.3s" className="col-md-12 col-sm-12 wow fadeInUp custCarousel">
            <h1 className={"text-center"}>
              <Text field={this.props.fields && this.props.fields.Title} tag="span" className="mb-2" />
            </h1>
            <Text field={this.props.fields && this.props.fields.Subtitle} tag="p" className="text-primary text-uppercase mb-5" />
            <div className="testimonial style-4">
              <Slider {...settings} >
                {contentList &&
                contentList.map((listItem, index) => (
                    <div key={`listItem-${index}`} >
                      <CarouselQouteItem
                          carouselLink={listItem.fields.Link}
                          carouselSlideImage={listItem.fields.CarouselSlideImage}
                          carouselLinkVideo={listItem.fields.LinkVideo}
                          carouselTitle={listItem.fields.Title}
                          carouselContent={listItem.fields.Content}
                          carouselButton={listItem.fields.Button}
                      />
                    </div>
                ))
                }
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withSitecoreContext()(CarouselQoutes);

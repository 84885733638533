import React from 'react';
import { Text, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from "../../Utils/NavLinkAdv";
import i18next from 'i18next';
import './footer__custom.css';

class Footer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {SocialLinks} = this.props.fields ? this.props.fields : '';
    const {MenuLinks} = this.props.fields ? this.props.fields : '';
    return(
      <footer className="main-ft">
        <div className="row top-ft">
          <div className="col-md-2 col-xs-12">
          <p>
              <NavLinkAdv to={"/"} >
                <img loading="lazy" className="logo img_custom_full" src={this.props.fields && this.props.fields.Logo && this.props.fields.Logo.value.src} />
              </NavLinkAdv>
            </p>
          </div>
          <div className="col-md-7 col-xs-12">
            
           <ul className="col-md-12 sidebar_bot pl-0 pb-0">
             {this.props.fields && this.props.fields.FooterMultilevel.map((footerMulti) => (
                 footerMulti.fields && footerMulti.fields.HeaderMenu.map((mainMenu, mainindex) =>
                    <li id={`mainind_${mainindex}`} className="col-md-4 pl-0 pr-0 pt-0" key={`foot-${mainindex}`}>
                   {mainMenu.fields.Title &&
                      mainMenu.fields.InternalLink && mainMenu.fields.InternalLink.value.href != '' &&
                       (
                          <NavLinkAdv to={mainMenu.fields.InternalLink.value.href} className=" first_link">
                            <Text field={mainMenu.fields.Title} />
                          </NavLinkAdv>
                       )
                   }
                   {mainMenu.fields.SubMenuLinks.length > 0 &&
                     <ul id={`subind_${mainindex}`} className="sub-menu">
                       {mainMenu.fields.SubMenuLinks.map((subMenu, subindex) => (
                          <li key={`sub_${subindex}`} className="menu-item">
                            {subMenu.fields.InternalLink && subMenu.fields.InternalLink.value.href != '' &&
                             (
                                <NavLinkAdv to={subMenu.fields.InternalLink.value.href}>
                                  <Text field={subMenu.fields.Title} />
                                </NavLinkAdv>
                             )}
                           </li>
                       ))}
                     </ul>
                    }
                 </li>
                 )
                ))
             }
           </ul>
          </div>
          <div className="col-md-3 col-xs-12">
            <p className="mt-0">
              <Text className="link_stext" field={this.props.fields && this.props.fields.Text} tag="strong" />
            </p>
            <p className="link_contact">
              <i className="fas fa-phone"></i> {i18next.t("ConsumerLabel")} : <span className="txt"><Text field={this.props.fields.TelephoneNumber} /></span><br />
              <i className="far fa-envelope" aria-hidden="true"></i> {i18next.t("EmailLabel")} : <span className="txt"><Text field={this.props.fields.EmailAddress} /></span>
            </p>
            <p className="link_social mt-0">
              { SocialLinks &&
              SocialLinks.map((listItem, index) => (
                  <React.Fragment key={index}>
                  {listItem.fields.InternalLink && listItem.fields.InternalLink.value.href.startsWith("http") === true ?
                    <a href={listItem.fields.InternalLink.value.href} target="_blank" key={index}>
                      <img loading="lazy" src={listItem.fields.Image && listItem.fields.Image.value.src} className="img_custom_full"/>
                    </a>
                    :
                      <NavLinkAdv to={listItem.fields.InternalLink.value.href} target="_blank" key={index}>
                        <img loading="lazy" src={listItem.fields.Image && listItem.fields.Image.value.src} className="img_custom_full"/>
                      </NavLinkAdv>
                  }
                  </React.Fragment>
              ))}
            </p>
            
            
          </div>                       
        </div>

        <div className="row_custom_small bottom-ft">
          <div className="col-md-12 pl-0">
            <p className="mb-0">
              <span className="">{i18next.t("CopyrightLabel")}</span>
              { MenuLinks &&    
                MenuLinks.map((listItem, index) => (
                    <React.Fragment key={index}>
                      {listItem.fields.InternalLink.value.href && listItem.fields.InternalLink.value.href.startsWith("http") === true ?
                          <Link field={listItem.fields.InternalLink} key={index} className="" />
                        :
                          <NavLinkAdv to={listItem.fields.InternalLink.value.href} key={index} className="">
                            {listItem.fields.InternalLink.value.text}
                          </NavLinkAdv>
                      }
                    </React.Fragment>
                ))
              }
               <a href="javascript:void(0);" className="optanon-toggle-display cookie-settings-button">{i18next.t("CookieConfigurationLabel")}</a>
            </p>
          </div>
        </div>
      </footer>


    );
  }
}

export default Footer;

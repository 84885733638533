import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from "../../Utils/NavLinkAdv";

class OverHead extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currLang: this.props.sitecoreContext.language
    };
  }

  render() {
    const {overHeadBannerContentList} = this.props.fields;
    
    return(
      <div className="row_custom_small tool-hd">
          <div className="col-md-12 grid-content">
                { overHeadBannerContentList &&    
                    overHeadBannerContentList.map((listItem, index) => (
                      <React.Fragment key={index}>
                        <NavLinkAdv to={listItem.fields.InternalLink.value.href} key={index} 
                          langactive={(listItem.fields.Title.value.toString().toLowerCase()==this.state.currLang.substring(0,2) ? "switch active" : "switch")} 
                          languageswitcher={(listItem.fields.Title.value=="EN"||listItem.fields.Title.value=="FR" ? true : false)} >
                         {listItem.fields.IconClass.value && <i className={listItem.fields.IconClass.value} aria-hidden="true"></i>} 
                         <Text field={listItem.fields.Title} /> 
                        </NavLinkAdv>
                      </React.Fragment>
                  ))}  
          </div>
      </div>
    )
  }
}

export default withSitecoreContext()(OverHead);
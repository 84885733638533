import React from 'react';
import {Text, Image, RichText, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import StarRating from '../StarRating';
import { ScaleLoader } from 'react-spinners';
import { css } from '@emotion/react';
import NavLinkAdv from "../../Utils/NavLinkAdv";
import OwlCarousel from 'react-owl-carousel2';
import 'react-owl-carousel2/lib/styles.css';
import 'react-owl-carousel2/src/owl.carousel.css';
import 'react-owl-carousel2/src/owl.theme.default.css';
import logo from '../../assets/img/placeholder.png';
import ReactComponent from "../../ReactComponent";
import i18next from 'i18next';
import { loader as gqlLoader } from "graphql.macro";
import { Query } from "react-apollo";
import './newCarousel.css';

const BestRatedProductsQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class BestRatedProducts extends ReactComponent {
  
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      productId: 0,
      currLang: this.props.sitecoreContext.language
    };
    this.carou = React.createRef();
  }


  render() {
    var ProductNumber = this.props.fields.ProductNumber.value;
    function SortByRating(x,y) {
      return ((x.item.rating.numberValue === y.item.rating.numberValue) ? 0 : ((x.item.rating.numberValue < y.item.rating.numberValue) ? 1 : -1 ));
    }
    const options = {
      items: 4,
      loop: true,
      lazyLoad: true,
      nav: true,
      dots: false,
      navText: ["<i class='fas fa-angle-left'></i>","<i class='fas fa-angle-right'></i>"],
      responsive: {
        990: {
          items: 4
        },
        768: {
          items: 3
        },
        426: {
          items: 2
        },
        0: {
          items: 1
        }
      }
    };
    return (
        <div className="row_custom_small related__product__custom bestRatedProd">
            <div className="col-md-12 pad ratedCustom">
                <h1>
                  <div>
                    <Text field={this.props.fields.title} tag="span"/>
                  </div>
                </h1>
            </div>
            <Query query={BestRatedProductsQuery} variables={{indexname: this.indexName, 
                              rootpath: '/sitecore/content/' + this.appName + '/', 
                              language: this.state.currLang, 
                              options: {"language": this.state.currLang}
                              }}>
              {({ loading, error, data}) => {
                if (loading)
                  return(
                    <div className='sweet-loading' style={{marginTop:'50px', marginBottom: '50px'}}>
                      <ScaleLoader
                        css={override}
                        sizeUnit={"px"}
                        size={50}
                        color={'#6e239b'}
                        loading={!this.state.isLoaded}
                      />
                    </div>
                  );
                if (error)
                  return (
                      <div>Error: {error.message}</div>
                  );
                if (data.search == null){
                  return(
                    <p className="text-center w-100">
                      Nothing to display.
                    </p>
                  );
                }else{
                  return (
                      data.search.results.items.length > 0 &&
                        <div className="col-md-12 carouselWrapper">
                          
                              <OwlCarousel ref={this.carou} options={options} className={" bestrated"}>
                              {data.search.results.items &&
                                data.search.results.items.filter(
                                  item => ((item.item !== null) && (item.item.id !== this.state.productId))).sort(SortByRating).slice(0, ProductNumber).map((product, index) => (
                                  <React.Fragment key={index}>
                                    <div key={`item-${index}`} className={"carousel_item_bestrated text-center"}>
                                      <NavLinkAdv to={product.item && product.item.url}>
                                        <div className="portfolio-caption min_font">
                                          <Text field={product.item && product.item.title} tag="h2"/>
                                        </div>
                                        <img loading="lazy" src={product.item && product.item.image1 && product.item.image1.src} className="img-fluid img_custom_full"/>
                                        <StarRating value={product.item && product.item.rating.numberValue}/>
                                        <div className="plus_corner">+</div>
                                        <NavLinkAdv to={product.item && product.item.url} className="btn btn_custom_o">{i18next.t("SeeMoreButton")}</NavLinkAdv>
                                      </NavLinkAdv>
                                    </div>
                                  </React.Fragment>
                              ))}
                              </OwlCarousel>
                          {/* <button className="btn chevrons_bestrated bestrated_pos prev" onClick={() => this.carou.current.prev()}>
                            <i className="fas fa-angle-left"></i></button>
                          <button className="btn chevrons_bestrated bestrated_pos next" onClick={() => this.carou.current.next()}>
                            <i className="fas fa-angle-right"></i></button> */}
    
                        </div>
                    )
                }
              }}
            </Query>
        </div>
    );
  }


}

export default withSitecoreContext()(BestRatedProducts);

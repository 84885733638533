import React from 'react';
import ReactComponent from "../../ReactComponent";
import {withSitecoreContext} from "@sitecore-jss/sitecore-jss-react";
import {loader as gqlLoader} from "graphql.macro";
import {ScaleLoader} from "react-spinners";
import {Query} from "react-apollo";
import {css} from "@emotion/react";
import './parentRoute.css';
import NavLinkAdv from "../../Utils/NavLinkAdv";

const parentRouteQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ParentRoute extends ReactComponent{

    constructor(props) {
        super(props);
        
        this.state = {
          error: null,
          isLoaded: false,
          ListParents: ''
        };
      }

    render(){
        return(
            <Query query={parentRouteQuery} variables={{currrentcontextitem: this.props.CurrentId}}>
                {({ loading, error, data}) => {
                    if (loading)
                        return (
                            <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                                <ScaleLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={50}
                                    color={'#6E239B'}
                                    loading={!this.state.isLoaded}
                                />
                            </div>
                        );
                    if (error)
                        return (
                            <div>Error: {error.message}</div>
                        );
                    return (
                        <React.Fragment>
                            <div className="breadcrumb-wrapper">
                                <ul className="breadcrumb__custom">
                                    {
                                        data.breadcrumbs && JSON.parse(data.breadcrumbs).ListParents.map((item, idx) => {
                                            let link = item.Link === "/en" ? "/" : item.Link;
                                            return(
                                            <li key={idx} className="itemListElement">
                                                <NavLinkAdv to={link}>
                                                <span>
                                                    {item.Text}
                                                </span>
                                                </NavLinkAdv>
                                            </li>
                                            )
                                        })
                                    }
                                    <li className="itemListElement">
                                    <span>
                                        {this.props.CurrentItemName}
                                    </span>
                                    </li>
                                </ul>
                            </div>
                        </React.Fragment>
                    );
                }}
            </Query>
        )
    }

}
export default withSitecoreContext()(ParentRoute);

import React from 'react';
import { Image, Text, RichText, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './TitleAndDescriptionAndImage.css';


const TitleAndDescriptionAndImage = (props) => {
    const myTitle = props.fields && props.fields.TitleColor && props.fields.TitleColor.fields.title && props.fields.TitleColor.fields.title.value != "" ? props.fields.TitleColor.fields.title.value : "";
    return (
      <div className={'row_custom_small box-1 tadai ' + myTitle}>
        <div className="row_custom_small section3">
          <div className="col-md-12 pad text-left">
            {props.fields && (
              <span>
                {props.fields.Title && props.fields.Title.value !== '' && (
                  <Text field={props.fields.Title} tag="h2" />
                )}
                {props.fields.ImageLocation &&
                  props.fields.Image &&
                  props.fields.ImageLocation.fields.title.value ===
                    'Before Description' && (
                    <img
                      loading="lazy"
                      src={props.fields.Image && props.fields.Image.value.src}
                      className="img_custom_full"
                    />
                  )}
                {props.fields.Description &&
                  props.fields.Description.value !== '' && (
                    <blockquote>
                      {' '}
                      <RichText
                        field={props.fields.Description}
                        tag="div"
                      />{' '}
                    </blockquote>
                  )}
              </span>
            )}
            {props.fields &&
              props.fields.ImageLocation &&
              props.fields.Image &&
              props.fields.ImageLocation.fields.title.value !==
                'Before Description' && (
                <img
                  loading="lazy"
                  src={props.fields.Image && props.fields.Image.value.src}
                  className="img_custom_full"
                />
              )}
          </div>
        </div>
      </div>
    );
};

export default withSitecoreContext()(TitleAndDescriptionAndImage);

import React from 'react';
import './checkOutVideo.css';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'

class CheckOutVideo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const tempArray =this.props.fields.VideoUrl.value.split('/')
    const ytbId = tempArray[tempArray.length - 1];

    let posterQuality = 'maxresdefault';
    if (ytbId === 'ee1qrYz5YHc') posterQuality = 'hqdefault';

    return (
      <div className="row_custom_small">
        <div className="col-md-12 text-center video__pad">
          <LiteYouTubeEmbed
            id={ytbId}
            poster={posterQuality}
            title="Dark and Lovely"
          />
        </div>
      </div>
    );
  }
}

export default CheckOutVideo;

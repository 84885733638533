import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import StarRating from '../StarRating';
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";
import {css} from "@emotion/react";
import {ScaleLoader} from "react-spinners";
import {Query} from "react-apollo";

const ProductRatingQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ProductRating extends ReactComponent {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }

  // getRating(data) {
  //   console.log(data);
  //   console.log(this.props);
    
  //   let sumRating = 0;
  //   let numRating = 1;
  //   let averageRate = 0;
  //   data && data.productrating && data.productrating.results.items && data.productrating.results.items.map((rating) => {
  //     numRating = data.productrating.results.items.length;
  //     sumRating += rating.item.rating.numberValue;
  //   });
  //   averageRate = sumRating / numRating;

  //   return averageRate;
  // }

  render() {

    return(

        <StarRating value={this.props.productid.item.rating.numberValue}/>
 
    )
  }
}

export default withSitecoreContext()(ProductRating);

import React from 'react';
import {Image, RichText, withSitecoreContext} from '@sitecore-jss/sitecore-jss-react';
import ListProductVariant from '../ListProductVariant';
import StarRating from '../StarRating';
import productMas from '../../assets/img/product-mas.svg';
import {NavLink} from "react-router-dom";
import i18next from 'i18next';
import ReactComponent from "../../ReactComponent";
import {loader as gqlLoader} from "graphql.macro";
import {ScaleLoader} from "react-spinners";
import {Query} from "react-apollo";
import {css} from "@emotion/react";

const ProductGridQuery = gqlLoader('./query.graphql');
const override = css`
  margin: auto;
`;

class ProductGridItem extends ReactComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded:  false,
            currLang: this.props.sitecoreContext.language
        }
    }

    getRating(data) {
        let sumRating = 0;
        let numRating = 1;
        let averageRate = 0;
        data.productrating && data.productrating.results.items && data.productrating.results.items.map((rating) => {
            numRating = data.productrating.results.items.length;
            sumRating += rating.item.rating.numberValue;
        });
        averageRate = sumRating / numRating;

        return averageRate;
    }

    render() {
        const productId = this.props.product.item !== null ? this.props.product.item.id : '';

        return (
            <Query query={ProductGridQuery} variables={
                {
                    indexname: this.indexName,
                    rootpath: '/sitecore/content/' + this.appName + '/',
                    rootpathrating: '/sitecore/content/' + this.appName + '/Content/Rating and Reviews',
                    productid: productId,
                    language: this.state.currLang,
                    options: {"language": this.state.currLang}
                }
            }>
                {({ loading, error, data}) => {
                    if (loading)
                        return (
                            <div className='sweet-loading' style={{marginTop: '50px', marginBottom: '50px'}}>
                                <ScaleLoader
                                    css={override}
                                    sizeUnit={"px"}
                                    size={50}
                                    color={'#6e239b'}
                                    loading={!this.state.isLoaded}
                                />
                            </div>
                        );
                    if (error)
                        return (
                            <div>Error: {error.message}</div>
                        );

                    const product = this.props.product;
                    const columnsize = this.props.columnsize;

                    return (
                        product.item !== null &&
                            <div className={`col-md-${columnsize.value === "4" || columnsize === null ? "3" : "4"} Producto-item`} id={(product.item.id).substr(5)}>
                                <NavLink to={product.item.url} className="link__custom__item">
                                <h2 className="title-cn">{product.item.title.value}</h2>
                                <span className="img-cn">
                                    <img loading="lazy" src={product.item.image1 &&product.item.image1.src}  className="img_custom_full"/>
                                </span>
                                <StarRating value={this.getRating(data)}/>
                                <div className="plus_corner">+</div>
                                <button type="button" className="btn btn_custom_o">{i18next.t("SeeMoreButton")} </button>
                                </NavLink>
                            </div>
                        );
                    }}
            </Query>
        )
    }
}

export default withSitecoreContext()(ProductGridItem);

import React from 'react';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';
import './categoriesNavigation.css';

class CategoriesNavigation extends React.Component {
    constructor(props) {
        super(props);
    }

    setSearchState(listItem) {
        let categoryList = [];
        let categoryNameList = [];
        categoryList.push(listItem.id.replace(/-/g, ""));
        categoryNameList.push(listItem.fields.title.value);
        sessionStorage.setItem("filterCategories", JSON.stringify(categoryList));
        sessionStorage.setItem("filterCategoriesName", JSON.stringify(categoryNameList));
        window.location.href = this.props.fields.tagLink && this.props.fields.tagLink.value.href + "?tagId=" + listItem.id.split('-').join('').toLowerCase()
    }

    render() {
        const { categoriesNavigationList } = this.props.fields;

        return (
            <div className="row_custom_small section2">
                <div className="row_custom_small col-md-12 pad text-center">
                    <h1>
                        <div>
                            <Text field={this.props.fields && this.props.fields.title} tag="span" />
                        </div>
                    </h1>
                    <h2 className="mb-0 w-100">
                        <Text field={this.props.fields && this.props.fields.subtitle} />
                    </h2>
                </div>
                <div className="row_custom_small nextSec2">
                    {categoriesNavigationList &&
                        categoriesNavigationList.map((listItem, index) => (
                            <div className="col-md-4 col-sm-6 col-xs-6 portfolio-item text-center" key={`sharedListItem-${index}`}>
                                <div onClick={this.setSearchState.bind(this, listItem)} className="portfolio-link" >
                                <img loading="lazy" className="img-fluid img_custom_full" src={listItem.fields && listItem.fields.image && listItem.fields.image.value.src} alt="" />
                                    <div className="portfolio-caption">
                                        <h2>
                                            <Text field={listItem.fields && listItem.fields.title} />
                                        </h2>
                                        <div>
                                            <RichText tag="p" field={listItem.fields && listItem.fields.description} />
                                        </div>
                                    </div>
                                    <div className="hover_border"></div>
                                </div>
                                {listItem.fields && listItem.fields.ctadisplayname && listItem.fields.ctadisplayname.value !== "" &&
                                    <div onClick={this.setSearchState.bind(this, listItem)} className="btn btn_custom btn__custom text-uppercase">
                                        <Text field={listItem.fields && listItem.fields.ctadisplayname} />
                                    </div>
                                }
                            </div>
                        ))}
                </div>
            </div>
        );
    }
}

export default CategoriesNavigation;

import React from 'react';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import './contact.scss';

const ContactForm = (props) => (
    <div className={"contactForm"}>
        <Text field={props.fields.contactUsTitle} tag={"h1"} />
        <iframe id="WebformLOreal" width="100%" height="1900px" frameBorder="0" scrolling="no" src={props.fields.contactUsURL.value} />
    </div>
);
export default withSitecoreContext()(ContactForm);
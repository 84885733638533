import React from 'react';
import { Image, Text, RichText, Link } from '@sitecore-jss/sitecore-jss-react';
import NavLinkAdv from '../../Utils/NavLinkAdv';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import './carouselQuoteItem.css';

const CarouselQouteItem = (props) => {
  let carouselItem = [];
  let ytbId;

  if (
    props &&
    props.carouselLinkVideo &&
    props.carouselLinkVideo.value &&
    props.carouselLinkVideo.value.linktype === 'external'
  ) {
    let temparray = props.carouselLinkVideo.value.url.split('/');
    ytbId = temparray[temparray.length - 1];
  }

  if (props && props.carouselLink && props.carouselLink.value.href) {
    carouselItem.push(
      <NavLinkAdv
        to={
          props &&
          props.carouselLink &&
          props.carouselLink.value &&
          props.carouselLink.value.href
        }>
        <Image
          loading="lazy"
          field={props && props.carouselSlideImage}
          editable={'true'}
          className="img_custom_full"
        />
      </NavLinkAdv>
    );
  } else {
    carouselItem.push(
      <Image
        loading="lazy"
        field={props && props.carouselSlideImage}
        editable
        className="img_custom_full"
      />
    );
  }

  return (
    <div className="testimonial-wrap">
      {Object.keys(carouselItem[0].props.field.value).length !== 0 ? (
        <div className="slideWrapper">
          <div className={'btn btn_new text-uppercase custom_Slide'}>
            {props.carouselLink ? (
              <NavLinkAdv
                to={props.carouselLink.value && props.carouselLink.value.href}>
                <Image
                  loading="lazy"
                  field={props.carouselSlideImage}
                  editable
                  className="img_custom_full"
                />
              </NavLinkAdv>
            ) : (
              <Image
                loading="lazy"
                field={props && props.carouselSlideImage}
                editable
                className="img_custom_full"
              />
            )}
          </div>
          {props &&
          props.carouselTitle.value && ( // Display only if title contributed
              <div className="overSlide text-left">
                {props && props.carouselTitle && (
                  <Text
                    field={props && props.carouselTitle}
                    tag={'h2'}
                    className={'text-uppercase'}
                  />
                )}
                {props && props.carouselContent && (
                  <RichText
                    field={props && props.carouselContent}
                    tag={'span'}
                  />
                )}
                {props &&
                props.carouselButton &&
                props.carouselButton.value &&
                props.carouselButton.value.href !== '' ? (
                  <NavLinkAdv
                    to={
                      props &&
                      props.carouselButton.value &&
                      props.carouselButton.value.href
                    }
                    className={'btn btn_new text-uppercase'}>
                    {props &&
                      props.carouselButton &&
                      props.carouselButton.value &&
                      props.carouselButton.value.text}
                  </NavLinkAdv>
                ) : (
                  ''
                )}
              </div>
            )}
        </div>
      ) : (
        <>
          {props &&
            props.carouselLinkVideo &&
            props.carouselLinkVideo.value &&
            props.carouselLinkVideo.value.linktype === 'external' && (
              <LiteYouTubeEmbed
                id={ytbId}
                poster="maxresdefault"
                title="Dark and Lovely"
                aspectHeight={6}
              />
            )}
          {props &&
            props.carouselLinkVideo &&
            props.carouselLinkVideo.value &&
            props.carouselLinkVideo.value.linktype === 'media' && (
              <video className="video-fluid vid_cust" loop autoPlay muted>
                <source
                  src={
                    props &&
                    props.carouselLinkVideo &&
                    props.carouselLinkVideo.value &&
                    props.carouselLinkVideo.value.href
                  }
                  type="video/mp4"
                />
              </video>
            )}
        </>
      )}
    </div>
  );
};

export default CarouselQouteItem;

import React from 'react';
import ReactComponent from "../../ReactComponent";
import { loader as gqlLoader } from 'graphql.macro';
import { Query } from "react-apollo";
import {geolocated} from 'react-geolocated';
import locationIcon from '../../assets/img/whiteLocation2.png';
import packageJson from '../../../package.json';
import './StoreLocator.css';

const StoreLocatorQuery = gqlLoader('./StoreLocator.graphql');
const _ = require("lodash");
const { compose, withProps, lifecycle, withStateHandlers } = require("recompose");
const { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow,} = require("react-google-maps");
const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");
const rootpathString = "/sitecore/content/" + packageJson.config.appName + "/Content/StoreLocator/Stores";

function distance(lat1, lon1, lat2, lon2, unit) {
  if ((lat1 === lat2) && (lon1 === lon2)) {
    return 0;
  }
  else {
    let radlat1 = Math.PI * lat1/180;
    let radlat2 = Math.PI * lat2/180;
    let theta = lon1-lon2;
    let radtheta = Math.PI * theta/180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = dist * 180/Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") { dist = dist * 1.609344 }
    if (unit === "N") { dist = dist * 0.8684 }
    return dist;
  }
}

function getNearestStores(markers, stores, dist, currentLatitude, currentLongitude) {
  let nearestStores = [];
  if(currentLatitude !== 0 && currentLongitude !== 0){
    stores &&
    stores.map((listItem) => {
      nearestStores.push([distance(currentLatitude, currentLongitude, Number(listItem.item.latitude.value), Number(listItem.item.longitude.value), "K"), Number(listItem.item.latitude.value), Number(listItem.item.longitude.value), listItem.item.storeName.value, listItem.item.adress.value, listItem.item.phone.value, listItem.item.email.value, listItem.item.openingHours.value]);
    });
  }
  else{
    markers.map((marker) =>
        stores &&
        stores.map((listItem) => {
          nearestStores.push([distance(marker.position.lat(), marker.position.lng(), Number(listItem.item.latitude.value), Number(listItem.item.longitude.value), "K"), Number(listItem.item.latitude.value), Number(listItem.item.longitude.value), listItem.item.storeName.value, listItem.item.adress.value, listItem.item.phone.value, listItem.item.email.value, listItem.item.openingHours.value]);
        })
    );
  }
  nearestStores = nearestStores.filter(function (el) { return (el[0] <= dist); });
  nearestStores = nearestStores.sort((a, b) => a[0] - b[0]);
  return nearestStores;
}

const MapWithASearchBox = compose(
    withStateHandlers(() => ({
      isOpen: false,
      infoIndex: null,
      state: false,
      hoverIndex: null,
      currentLatitude : 0,
      currentLongitude : 0,
      locationUpdate : false
    }), {
      updateLatLong :({locationUpdate}) => (latitude, longitude) =>  ({
        currentLatitude : latitude,
        currentLongitude : longitude,
        locationUpdate : !locationUpdate
      }),
      initLatLong :() => () =>  ({
        currentLatitude : 0,
        currentLongitude : 0
      }),
      showInfo: ({ isOpen, infoIndex }) => (index) => ({
        isOpen: infoIndex !== index || !isOpen,
        infoIndex: index
      }),
      handleMouse: ({ state, hoverIndex }) => (index) => ({
        state: hoverIndex !== index || !state,
        hoverIndex: index
      }),
    }),
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?client=gme-lorealsa&channel=" + packageJson.config.appName + "&v=quartely&libraries=geometry,drawing,places",
        loadingElement: <div className={'loadingElement'} />,
      containerElement: <div className={'containerElement'} />,
      mapElement: <div className={'mapElement'} />,
    }),
    lifecycle({
      componentWillMount() {
        const refs = {};
        this.setState({
          bounds: null,
          center: {
              lat: 5.964300, lng: 17.524178
          },
          markers: [],
          onMapMounted: ref => {
            refs.map = ref;
          },
          onBoundsChanged: _.debounce( () => {
                this.setState({
                  bounds: refs.map.getBounds(),
                  center: refs.map.getCenter(),
                });
                let { onBoundsChange } = this.props;
                if (onBoundsChange) {
                  onBoundsChange(refs.map)
                }
              },
              100,
              { maxWait: 500 }
          ),
          onSearchBoxMounted: ref => {
            refs.searchBox = ref;
          },
          onPlacesChanged: () => {
            const places = refs.searchBox.getPlaces();
            const bounds = new window.google.maps.LatLngBounds();

            places.forEach(place => {
              if (place.geometry.viewport !== undefined && place.geometry.viewport) {
                bounds.union(place.geometry.viewport)
              } else {
                bounds.extend(place.geometry.location)
              }
            });
            const nextMarkers = places.map(place => ({
              position: place.geometry.location,
            }));
            const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

            this.setState({
              center: nextCenter,
              markers: nextMarkers,
            });
            refs.map.fitBounds(bounds);
          },
        })
      },
    }),
    withScriptjs,
    withGoogleMap
)(props =>
    <GoogleMap ref={props.onMapMounted} defaultZoom={11} center={props.center} onBoundsChanged={props.onBoundsChanged}>
      <SearchBox
          ref={props.onSearchBoxMounted}
          bounds={props.bounds}
          controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
          onPlacesChanged={props.onPlacesChanged}
      >
        <div className={'div_ppp_searchbox'}>
          <input type="text" placeholder="Find your store" onClick = {props.initLatLong}
            onKeyPress={event => {
              if (event.key === 'Enter') {
                props.initLatLong()
              }
            }}
          />
          <button className={'btn_geo'} onClick={() => props.updateLatLong(props.currentLat, props.currentLong)}>
            <img loading="lazy" src={locationIcon} alt={""}/>
          </button>
        </div>
      </SearchBox>
      <div className={'moreStore'}>
        {getNearestStores(props.markers, props.stores, Number(props.distance), props.currentLatitude, props.currentLongitude).map((store, index) =>
            <React.Fragment key={index}>
              <div className={'storeMap'} style={{backgroundColor: (props.state && props.hoverIndex === index) ? "#f4f4f4" : "#fff"}} onMouseEnter={() => props.handleMouse(index)} onMouseLeave={() => props.handleMouse(index)}>
                <i className="fas fa-map-marker-alt"></i>
                <br/>
                <strong>
                  {store[3]}
                </strong>
                {store[4]}
                <br/>
                  Phone: {store[5]}
                <br/>
                  {store[7]}
                <br/>
                <a className={'rest_map'} onClick={() => props.showInfo(index)} >
                  <i className="fas fa-map-marker-alt"></i> Map
                </a>
                <br/>
              </div>
              <Marker key={index} position={{ lat: store[1], lng: store[2] }}  onClick={() => props.showInfo(index)}
                      icon={{ url: (props.state && props.hoverIndex === index) ? props.markerIconHover : props.markerIcon }}
                      onMouseOver={() => props.handleMouse(index)} onMouseOut={() => props.handleMouse(index)}>
                { (props.isOpen && props.infoIndex === index)  && <InfoWindow onCloseClick={props.onToggleOpen}>
                  <React.Fragment key={index}>
                    <div>
                        <p style={{color: `#686868`, paddingBottom: `8px`, margin: `0`, fontSize: `15px`}}>
                            <span style={{color: `#c7c7c7`, fontSize: `11px`, fontWeight: `normal`}}>Contact information</span>
                            <br/>
                            <strong style={{fontWeight: `500`, textTransform: `uppercase`, fontFamily: `Roboto, Arial, sans-serif`}}>
                                {store[3]}
                            </strong>
                            <br/>
                            {store[4]}
                        </p>
                        <p style={{fontSize: `14px`, margin: `0`}}>
                            Phone: {store[5]}
                            <br/>
                            {store[7]}
                        </p>
                    </div>
                  </React.Fragment>
                </InfoWindow>}
              </Marker>
            </React.Fragment>
        )}
      </div>
    </GoogleMap>
);

const StoreLocatorList = (props) => (
    <Query query={StoreLocatorQuery} variables={{ indexname: props.indexName, rootpath: rootpathString }}>
        {({ loading, error, data }) => {
            if (loading) return "Loading...";
            if (error) return `Error! ${error.message}`;
            return (
                <React.Fragment key={`store`}>
                    <div className={"row_custom_small text-center storeList"}>
                        <MapWithASearchBox stores={data.search.results.items} distance={props.distance} markerIcon={props.markerIcon} markerIconHover={props.markerIconHover} currentLat={props.latitude} currentLong={props.longitude} />
                    </div>
                </React.Fragment>
            );
        }}
    </Query>
);

class CurrentGeolocation extends ReactComponent {
    
    render() {
        return !this.props.isGeolocationAvailable
            ? <div>Your browser does not support Geolocation</div>
            : !this.props.isGeolocationEnabled
                ? <div>Geolocation is not enabled</div>
                : this.props.coords
                    ? <StoreLocatorList latitude={this.props.coords.latitude} longitude={this.props.coords.longitude} distance={this.props.fields.distances.fields.distance.value} markerIcon={this.props.fields.markerIcon.value.src} markerIconHover={this.props.fields.markerIconHover.value.src} indexName={this.indexName} />
                    : <div>Getting the location data&hellip; </div>;
    }
}

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(CurrentGeolocation);
